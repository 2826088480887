<script>
import CardButtons from "@/components/molecules/CardButtons.vue";
import AppImage from "@/components/atoms/AppImage.vue";
import AppCard from "@/components/atoms/AppCard.vue";
import AppForm from "@/components/atoms/AppForm.vue";

export default {
  components: { CardButtons, AppImage, AppCard, AppForm },
  name: "ModularCard",
  props: {
    cardModules: Object,
    hasCancel: {
      type: Boolean,
      default: true,
    },
    buttonBackDisabled: {
      type: Boolean,
      default: false,
    },
    buttonNextDisabled: {
      type: Boolean,
      default: false,
    },
    buttonBackText: String,
    customStyle: {
      type: String,
      default: "min-height: calc(100% - 44px);",
    },
  },
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    },
    submit() {
      this.$emit("submit");
    },
    change(payload) {
      this.$emit("change", payload);
    },
    click() {
      this.$emit("click");
    },
    handleDefaultClick(payload) {
      console.log(payload);
    },
  },
};
</script>

<template>
  <app-card
    class="card-default"
    :customStyle="customStyle + 'padding: 32px; width: 100%;'"
  >
    <!-- TODO: stepListItem . eachCardresponsability -->
    <!-- foreach module.key -> -->
    <div
      v-for="item in cardModules"
      v-bind:key="item.key"
      class="step-card-module"
    >
      <div v-if="item.type === 'title'" class="step-card-title">
        <p>{{ item.title }}</p>
      </div>
      <div v-if="item.type === 'subtitle'" class="step-card-subtitle">
        {{ item.subtitle }}
      </div>
      <span v-if="item.type === 'image'" class="step-card-image">
        <app-image :image="item.image" :customStyle="item.customStyle" />
      </span>
      <span v-if="item.type === 'form'" class="step-card-form">
        <app-form
          :formName="item.name"
          :inputList="item.form"
          @change="change"
        />
      </span>
      <div class="step-card-divider"></div>
      <span v-if="item.type === 'buttons'" class="step-card-">
        <card-buttons
          :buttons="item.buttons"
          :buttonBackDisabled="buttonBackDisabled"
          :buttonNextDisabled="buttonNextDisabled"
          :buttonBackText="buttonBackText"
          @previous="previous"
          @next="next"
          @submit="submit"
          @click="click"
        />
        <slot />
      </span>
    </div>
  </app-card>
</template>

<style lang="scss">
/* .step-card-content {
  display: flex;
  padding: 18px 16px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 26px;
  color: #323232;
} */

.card-default {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 18px;
  color: #323232;
  max-width: 448px;
  margin: 0 auto;
}
.step-card-title {
  p {
    font-family: "Quicksand";
    font-size: 22px !important;
    font-weight: 600;
    color: #323232;
  }
}
.step-card-subtitle {
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 124%;
  }
}
.step-card-image {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
