<script>
import AppButton from "@/components/atoms/AppButton.vue";

export default {
  components: { AppButton },
  name: "CardButtons",
  data() {
    return {
      form: {},
    };
  },
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    buttonBackDisabled: {
      type: Boolean,
      default: false,
    },
    buttonNextDisabled: {
      type: Boolean,
      default: false,
    },
    buttonBackText: String,
  },
  methods: {
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    },
    submit() {
      this.$emit("submit");
    },
    click() {
      this.$emit("click");
    },
    change(payload) {
      this.$emit("change", payload);
    },
  },
};
</script>

<template>
  <span class="card-buttons-container">
    <!-- change form button colors everywhere -->
    <app-button
      v-if="buttons.indexOf('previous') != -1"
      name="previous"
      :text="buttonBackText"
      type="new-return"
      :icon="
        buttonBackText.toLowerCase() == 'cancelar'
          ? 'mdi-exit-to-app'
          : 'mdi-chevron-left'
      "
      width="calc(50% - 4px)"
      :disabled="buttonBackDisabled"
      @click="previous"
    />
    <app-button
      v-if="buttons.indexOf('next') != -1"
      name="next"
      text="Próximo"
      icon="mdi-chevron-right"
      iconPosition="right"
      type="new-default"
      width="calc(50% - 4px)"
      :customStyle="
        buttonNextDisabled
          ? ''
          : 'color: white !important; background: radial-gradient(95.65% 850.19% at 3.57% 9.78%, #F2AA55 0%, #FFC581 100%);'
      "
      :disabled="buttonNextDisabled"
      @click="next"
    />
    <app-button
      v-if="buttons.indexOf('submit') != -1"
      name="submit"
      icon="mdi-content-save-check-outline"
      text="Finalizar"
      :type="buttonNextDisabled ? 'new-default' : 'new-return'"
      width="100%"
      customStyle="color: white; margin-top: 16px; "
      @click="submit"
    ></app-button>

    <app-button
      v-if="buttons.indexOf('link') != -1"
      name="submit"
      text="Gerar Link"
      icon="mdi-share-variant-outline"
      type="new-return"
      width="100%"
      customStyle="color: white; margin-top: 16px; "
      @click="click"
    ></app-button>
  </span>
</template>

<style lang="scss">
.card-buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
}
</style>
