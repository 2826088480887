<script>
import ModularCard from "@/components/molecules/ModularCard.vue";

export default {
  name: "OneTimeExpiredTemplate",
  components: { ModularCard },
  data() {
    return {
      linkExpiredCard: {
        0: {
          type: "title",
          title: "Link Expirado!",
        },
        1: {
          type: "image",
          image: "invalid-access",
        },
        2: {
          subtitle: "Solicite para seu sensei gerar um novo link de acesso.",
          type: "subtitle",
        },
        3: {
          type: "subtitle",
          subtitle:
            "Somente membros autorizados tem acesso a suas informações.",
        },
      },
    };
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <span class="section-container message-template" style="padding: 16px">
    <modular-card
      :cardModules="linkExpiredCard"
      customStyle="min-height: calc((var(--vh, 1vh) * 100) - 32px) !important; justify-content: flex-start; gap: 32px;"
    />
  </span>
</template>

<style lang="scss" scoped></style>
